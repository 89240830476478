// libs
import React from 'react';

// main component
const ImageSlider = () => (
    <div>
        <div className="line"></div>
        <section className="container clearfix">
            <div id="oc-clients-full" className="owl-carousel image-carousel carousel-widget" data-margin="30" data-loop="true" data-nav="false" data-autoplay="5000" data-pagi="false" data-items-xs="2" data-items-sm="3" data-items-md="4" data-items-lg="5" data-items-xl="6">

                <div className="oc-item"><a href="https://www.teaeapae.gr/" target="_blank"><img src="img/teaeapae.jpg" alt="Clients" style={{maxHeight: 100, margin: 'auto', verticalAlign: 'baseline'}} /></a></div>
                <div className="oc-item"><a href="https://eteapep.gr/" target="_blank"><img src="img/eteapep.jpg" alt="Clients" style={{maxHeight: 100, margin: 'auto', verticalAlign: 'baseline'}}/></a></div>
                <div className="oc-item"><a href="https://teayfe.gr/" target="_blank"><img src="img/teayfe.png" alt="Clients" style={{maxHeight: 100, margin: 'auto', verticalAlign: 'baseline'}}/></a></div>
                <div className="oc-item"><a href="https://www.teayet.gr/site/" target="_blank"><img src="img/teayet.png" alt="Clients" style={{maxWidth: '50%', height: 'auto', margin: 'auto', verticalAlign: 'baseline'}}/></a></div>

            </div>
        </section>
    </div>
);

// export
export default ImageSlider;