// libs
// libs
import React from 'react';

// main component
const Promo = () => (
    <section id="promo" className="promo promo-full promo-border p-5 header-stick">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12">
                    <h4 style={{textAlign: 'justify'}}>
                        Η Ένωσή μας είναι μη κερδοσκοπική και μη κυβερνητική οργάνωση μελέτης, προστασίας, βελτίωσης, προαγωγής, εγγύησης των ασφαλιστικών
                        και οικονομικών δικαιωμάτων των ασφαλισμένων μελών του κάθε Ταμείου-Μέλος της, έκφρασης της επαγγελματικής
                        αλληλεγγύης και διασφάλισης της βιωσιμότητας του θεσμού των επαγγελματικών ταμείων υποχρεωτικής και προαιρετικής
                        επικουρικής και συμπληρωματικής ασφαλιστικής προστασίας.
                    </h4>
                </div>
            </div>
        </div>
        <div className="clear"></div>
    </section>
);

// export
export default Promo;