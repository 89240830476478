// libs
import React from 'react';

// main component
const Points = () => (
    <div>
        <div className="line"></div>
        <section className="container clearfix">
            <div className="row col-mb-50">


                <div className="col-sm-6 col-lg-4">
                    <div className="feature-box fbox-effect">
                        <div className="fbox-icon">
                            <a data-toggle="modal" data-target="#coorporationModal"><i className="icon-handshake i-alt"></i></a>
                        </div>
                        <div className="fbox-content">
                            <h3>Συνεργασία</h3>
                            <p>Η Ένωση συνεργάζεται με τις αρμόδιες υπηρεσίες και αρχές της πολιτείας, καθώς και με τις αρμόδιες υπηρεσίες της Ευρωπαϊκής
                            Ένωσης και άλλων διεθνών οργανισμών. Επιπλέον, συνεργάζεται με φορείς, ερευνητικά κέντρα, πανεπιστήμια και ειδικούς επιστήμονες στην ημερδαπή και αλλοδαπή.</p>
                        </div>
                    </div>
                    <div className="modal fade" id="coorporationModal" tabindex="-1" role="dialog" aria-labelledby="membersModal" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content" style={{borderRadius: '10px'}}>
                                <div className="modal-body" style={{textAlign: 'justify'}}>
                                    <h4>Συνεργαζόμενοι Φορείς</h4>
                                    <p>...</p>
                                    <p>Κείμενο</p>
                                    <p>...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-sm-6 col-lg-4">
                    <div className="feature-box fbox-effect">
                        <div className="fbox-icon">
                            <a data-toggle="modal" data-target="#participationModal"><i className="icon-chalkboard-teacher i-alt"></i></a>
                        </div>
                        <div className="fbox-content">
                            <h3>Συμμετοχή</h3>
                            <p>Η Ένωση υλοποιεί και συμμετέχει σε εθνικά και ευρωπαϊκά προγράμματα για τη στήριξη των μελών της σε όλα τα επίπεδα.
                            Ακόμα, διοργανώνει διαλέξεις, ημερίδες, συνέδρια, σεμινάρια, εκθέσεις, εκπαιδευτικά προγράμματα και κάθε είδους εκδηλώσεις.</p>
                        </div>
                    </div>
                    <div className="modal fade" id="participationModal" tabindex="-1" role="dialog" aria-labelledby="membersModal" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content" style={{borderRadius: '10px'}}>
                                <div className="modal-body" style={{textAlign: 'justify'}}>
                                    <h4>Συμμετοχές</h4>
                                    <p>...</p>
                                    <p>Κείμενο</p>
                                    <p>...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-sm-6 col-lg-4">
                    <div className="feature-box fbox-effect">
                        <div className="fbox-icon">
                            <a data-toggle="modal" data-target="#publicationModal"><i className="icon-book i-alt"></i></a>
                        </div>
                        <div className="fbox-content">
                            <h3>Έκδοση</h3>
                            <p>
                            Εκδίδει μελέτες, ενημερωτικά έντυπα, βιβλία και χρησιμοποιεί τα ηλεκτρονικά μέσα (π.χ. διαδίκτυο) για τη διάχυση πληροφοριών.
                            </p>
                        </div>
                    </div>
                    <div className="modal fade" id="publicationModal" tabindex="-1" role="dialog" aria-labelledby="membersModal" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content" style={{borderRadius: '10px'}}>
                                <div className="modal-body" style={{textAlign: 'justify'}}>
                                    <h4>Εκδόσεις</h4>
                                    <p>...</p>
                                    <p>Κείμενο</p>
                                    <p>...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

                

                

                                

            </div>
        </section>
    </div>
);

// export
export default Points;