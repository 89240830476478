// libs
import React from 'react';

// components
import Header from './components/header';
import Slider from './components/slider';
import Promo from './components/promo';
import ColorColumns from './components/color_columns';
import RecentPosts from './components/widgets/recent_posts';
import Accordion from './components/widgets/accordion';
import Actions from './components/actions';
import ImageSlider from './components/image_slider';
import NewsLetter from './components/newsletter';
import Footer from './components/footer';
import Blog from './components/blog';

// main app component
const App = () => {

  // render
  return (
    <div id="wrapper" className="clearfix">
      
      {/* CONTENT */}
      <section id="content">
        <div className="content-wrap">
          <Promo />
          <ColorColumns />
          <div id="news" style={{marginBottom: '200px'}}/>
          {/*<Blog />*/}
          <div id="actions" />
          <Actions />
          <ImageSlider />
          {/*<NewsLetter />*/}
        </div>
      </section>
      {/* FOOTER */}
      <Footer />
    </div>
  );

}

// export
export default App;
