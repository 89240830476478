// libs
import React from 'react';

// main component
const Footer = () => (
    <footer id="footer" className="dark clearfix">
        <div id="copyrights">
            <div className="container">
                <div className="row col-mb-30">

                    <div className="col-md-6 text-center text-md-left">
                        &copy; 2020 Ε.Τ.Ε.Α.Ε.<br /> 
                        {/*<div className="copyright-links"><a href="#">Όροι Χρήσης</a> / <a href="#">Πολιτική Απορρήτου</a></div>*/}
                    </div>
                    
                    <div className="col-md-6 text-center text-md-right">
{/*
                        <div className="d-flex justify-content-center justify-content-md-end">
                            <a href="#" className="social-icon si-small si-borderless si-facebook">
                                <i className="icon-facebook"></i>
                                <i className="icon-facebook"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless si-twitter">
                                <i className="icon-twitter"></i>
                                <i className="icon-twitter"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless si-gplus">
                                <i className="icon-gplus"></i>
                                <i className="icon-gplus"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless si-pinterest">
                                <i className="icon-pinterest"></i>
                                <i className="icon-pinterest"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless si-vimeo">
                                <i className="icon-vimeo"></i>
                                <i className="icon-vimeo"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless si-github">
                                <i className="icon-github"></i>
                                <i className="icon-github"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless si-yahoo">
                                <i className="icon-yahoo"></i>
                                <i className="icon-yahoo"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless si-linkedin">
                                <i className="icon-linkedin"></i>
                                <i className="icon-linkedin"></i>
                            </a>
                        </div>
                    */}
                        <div className="clear"></div>

                        <i className="icon-envelope2"></i> info@eteae.gr <span className="middot">&middot;</span> <i className="icon-headphones"></i> +30 2111064412
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

// export
export default Footer;