// libs
import React from 'react';

// main component
const ColorColumns = () => (
    <section id="color-cols" className="row bottommargin-lg align-items-stretch">

        <div className="col-lg-4 dark col-padding overflow-hidden" style={{backgroundColor: '#ff6666'}}>
            <div>
                <h3 className="text-uppercase" style={{fontWeight: 600}}>ΜΕΛΗ</h3>
                <a className="button button-border button-light button-rounded text-uppercase m-0" data-toggle="modal" data-target="#membersModal">ΠΕΡΙΣΣΟΤΕΡΑ</a>
                <i className="icon-user-friends bgicon"></i>
            </div>
            <div className="modal fade" id="membersModal" tabindex="-1" role="dialog" aria-labelledby="membersModal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content" style={{backgroundColor: '#ff6666', borderRadius: '10px'}}>
                        <div className="modal-body" style={{textAlign: 'justify'}}>
                            <p>
                            Είσοδος νέου μέλους επιτρέπεται μετά από έγγραφη αίτησή του και απόφαση του Δ.Σ., που συνεδριάζει εκτάκτως για τον σκοπό αυτό και αποφασίζει
                            με πλειοψηφία 2/3.
                            </p>
                            <p>
                            Κάθε νέο μέλος της Ένωσης εκπροσωπείται στο Δ.Σ. με ένα μέλος, αρχής γενόμενης από την 1η Ιανουαρίου του αμέσως επόμενου από την είσοδό του
                            έτος, παραιτούμενο για το σκοπό αυτό ενός μέλους εκ των Ταμείων που εκπροσωπούνται με δύο μέλη (ιδρυτικών). Η σειρά διαδοχής των εκπροσώπων
                            των ιδρυτικών μελών που θα εκπροσωπούνται στο Δ.Σ. με δύο μέλη ορίζεται με απόφαση του Δ.Σ. που λαμβάνεται με πλειοψηφία 2/3 στην πρώτη
                            συνδρίαση κάθε έτους.
                            </p>
                            <p>Τα μέλη εισφέρουν ετήσιες τακτικές και έκτακτες εισφορές στην Ένωση, το ύψος των οποίων καθορίζεται με απόφαση του Δ.Σ.</p>
                            <p>Σε περίπτωση εμφάνισης πλεονεσμάτων, δύναται τα μέλη να απαλλάσσονται αναλογικά από την καταβολή ετήσιας εισφοράς, λαμβάνοντας
                            υπόψη τις ανάγκες της Ένωσης βάσει του Ετήσιου Προγραμματισμού Δράσεων και του Προϋπολογισμού.</p>
                            <p>Πέραν των εισφορών των μελών, η περιουσία της Ένωσης περιλαμβάνει και τις συνδρομές των φυσικών και νομικών προσώπων που συνδέονται
                            με οποιοδήποτε καθεστώς με την Ένωση, οι πάσης φύσεως δωρεές ή επιχορηγήσεις, τα έσοδα και οι αμοιβές από εκπόνηση μελετών, οργάνωση
                            συνεδρίων, συμμετοχές σε εκδηλώσεις, παραγωγή εξειδικευμένων σεμιναρίων κ.ο.κ.</p>
                            <p>Τα μέλη δεν ευθύνονται για υποχρεώσεις της Ένωσης ενοχικού ή άλλου χαρακτήρα προς τρίτους, πέραν της εισφοράς τους.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-4 dark col-padding overflow-hidden" style={{backgroundColor: '#c89d46'}}>
            <div>
                <h3 className="text-uppercase" style={{fontWeight: 600}}>ΣΚΟΠΟΣ</h3>
                <a href="#" className="button button-border button-light button-rounded text-uppercase m-0"  data-toggle="modal" data-target="#purposeModal">ΠΕΡΙΣΣΟΤΕΡΑ</a>
                <i className="icon-bullseye bgicon"></i>
            </div>
            <div className="modal fade" id="purposeModal" tabindex="-1" role="dialog" aria-labelledby="membersModal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content" style={{backgroundColor: '#c89d46', borderRadius: '10px'}}>
                        <div className="modal-body" style={{textAlign: 'justify'}}>
                            <p> Μέσα για την εκπλήρωση των σκοπών της Ένωσης είναι:</p>
                            <ul style={{paddingLeft: '10px'}}>
                                <li>Η οργάνωση των μελών της για την προάσπιση των συμφερόντων και δικαιωμάτων αυτών και των ασφαλισμένων τους.</li>
                                <li>Η δημιουργία, διατήρηση και ανάπτυξη των δεσμών ενότητας, αλληλεγγύης μεταξύ των μελών της και ηδημιουργική συμβολή στην ενότητας
                                και την ισχυροποίηση της θέσης και των δικαιωμάτων τους.</li>
                                <li>Η συμβολή στη διαμόρφωση κοινής και ενιαίας στρατηγικής για την αντιμετώπιση των κοινών προβλημάτων και την εξεύρεση
                                κοινά αποδεκτών λύσεων στα θέματα που αφορούν τα μέλης της.</li>
                                <li>Η παρακολούθηση της εφαρμογής της εθνικής και ευρωπαϊκής νομοθεσίας, καθώς και η συμβολή στη διαμόρφωση της νομοθεσίας για την
                                προστασία των δικαιωμάτων των μελών, των ασφαλισμένων, των εκπροσώπων και τη διασφάλιση του θεσμικού τους ρόλου.</li>
                                <li>Η συμβολή στην ανάπτυξη και ευόδωση του θεσμού της επαγγελματικής ασφάλισης.</li>
                                <li>Η διασφάλιση και κατοχύρωση της θέσης των μελών της σε εθνικό και ευρωπαϊκό επίπεδο.</li>
                                <li>Η ενημέρωση των ασφαλισμένων μελών, της κοινής γνώμης, των Μ.Μ.Ε., της Βουλής, των διαφόρων πολιτικών και ελληνικών ή διεθνών
                                ασφαλιστικών φορέων, επαγγελματικών ταμείων και των παραγόντων και άλλων αρμοδίων οργάνων, μέσω της έκδοσης ανακοινώσεων,
                                ειδικών εντύπων ή οργάνου τύπου με ομιλίες, συνεντεύξεις κτλ.</li>
                                <li>Ο σχεδιασμός, οργάνωση και υλοποίηση μελετών, σεμιναρίων, συνεδρίων και επιστημονικών ερευνών για το σύνολο των θεμάτων που 
                                αφορούν τον θεσμό της Επικουρικής Ασφάλισης, της Κοινωνικής Ασφάλισης, της Επαγγελματικής Ασφάλισης και τον Κοινωνικό Διάλογο.</li>
                                <li>Ο σχεδιασμός, οργάνωση και υλοποίηση στρατηγικών κοινών επενδύσεων των μελών της στα πλαίσια της κείμενης νομοθεσίας.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-4 dark col-padding overflow-hidden" style={{backgroundColor: '#216f83'}}>
            <div>
                <h3 className="text-uppercase" style={{fontWeight: 600}}>ΔΙΟΙΚΗΣΗ</h3>
                <a href="#" className="button button-border button-light button-rounded text-uppercase m-0" data-toggle="modal" data-target="#managementModal">ΠΕΡΙΣΣΟΤΕΡΑ</a>
                <i className="icon-sitemap bgicon"></i>
            </div>
            <div className="modal fade" id="managementModal" tabindex="-1" role="dialog" aria-labelledby="membersModal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content" style={{backgroundColor: '#216f83', borderRadius: '10px'}}>
                        <div className="modal-body" style={{textAlign: 'justify'}}>
                            <p>Η Ένωση διοικείται από το εννεαμελές Δ.Σ. (Διοικητικό Συμβούλιο). Η θητεία των μελών του διαρκεί για τέσσερα έτη.</p>
                            <ul style={{paddingLeft: '10px'}}>
                                <span style={{textDecoration: 'underline'}}>Όργανα του Δ.Σ. είναι:</span>
                                <li>Ο Πρόεδρος</li>
                                <li>Ο Αντιπρόεδρος</li>
                                <li>Ο Γραμματέας</li>
                                <li>Ο Ταμίας</li>
                                <li>Εκπρόσωποι Μελών</li>
                            </ul>
                            <ul style={{paddingLeft: '10px'}}>
                                <span style={{textDecoration: 'underline'}}>Αρμοδιότητες:</span>
                                <li>Έγκριση νέων μελών και διαγραφή μελών</li>
                                <li>Κατάρτιση και επεξεργασία του Ετήσιου Προϋπολογισμού και του Ετήσιου Απολογισμού</li>
                                <li>Κατάρτιση του Ετήσιου Προγραμματισμού</li>
                                <li>Απόφαση για κάθε θέμα που σχετίζεται με την εύρυθμη λειτουργία των υπηρεσιών της</li>
                                <li>Έγκριση του εσωτερικού κανονισμού λειτουργίας</li>
                                <li>Απόφαση για συμμετοχή σε επιστημονικά προγράμματα και δράσεις</li>
                                <li>Πρόσληψη αναγκαίου προσωπικού και εκχώρηση αρμοδιοτήτων συγκεκριμένων καθηκόντων</li>
                                <li>Απόφαση για κάθε θέμα που αφορά κληρονομιές, κληροδοσίεως και δωρεές</li>
                                <li>Τροποποίηση του Καταστατικου και λύση της Ένωσης</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="clear"></div>
    </section>
);

// export
export default ColorColumns;